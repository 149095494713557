<template>
  <table v-if='hasRequests'
    class='mt-2 text-sm table-fixed w-full'>
    <thead>
      <tr class='uppercase border-b'>
        <th class='w-24 py-2 pr-2 text-left'>요청 일자</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterTitle'
          defaultLabel='제목'
          class='w-40 p-2 text-left border-r' />
        <table-header-filter-text
            v-bind:filter-text.sync='filterDescription'
            defaultLabel='내용'
            class='w-1/3 p-2 text-left border-r' />
        <th class='w-32 p-2 text-left'>요청자</th>
        <th class='w-32 p-2 text-left'>승인자</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterApprovalStatus'
          defaultLabel='진행상태'
          class='w-24 p-2 text-left border-r' />
        <th class='w-40 p-2 text-left'>사건</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for='response in filteredapprovalResponses'
        :key='`my-approvals-${response.id}`'
        class='border-b'>
        <td class='py-2 pr-2'>{{response.created_on}}</td>
        <td class='p-2 border-r'>{{response.title}}</td>
        <td class='p-2 border-r'><div v-html='response.detail' v-linkified class='linked-text-container' /></td>
        <td class='p-2'>{{response.requested_user_name}}</td>
        <td class='p-2'>{{response.response_user_name}}</td>
        <td class='p-2 border-r'>{{statusString(response.status)}}</td>
        <td class='p-2'>
          <a :href='drilldownLink(response.investment_id)'
            target='_blank'
            class='underline hover:bg-gray-100 py-2'>
            {{ response.investment_name }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else
    class='bg-gray-100 text-center max-w-6xl p-16'>
    There are 0 requests that need your approval
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'

export default {
  name: 'MyApprovalsMyResponses',
  components: {
    TableHeaderFilterText,
  },
  data () {
    return {
      filterApprovalStatus: '',
      filterTitle: '',
      filterDescription: '',
    }
  },
  computed: {
    ...mapState('approvals', [
      'approvalResponses',
    ]),
    filteredapprovalResponses () {
      if (this.filterApprovalStatus === '' &&
          this.filterTitle          === '' &&
          this.filterDescription    === '') {
        return this.approvalResponses
      } else {
        return this.approvalResponses.filter(response =>
          (this.filterApprovalStatus === '' || StringHelpers.stringIncludesInsensitive(response.status, this.filterApprovalStatus)) &&
          (this.filterTitle === ''          || StringHelpers.stringIncludesInsensitive(response.title, this.filterTitle)) &&
          (this.filterDescription === ''    || StringHelpers.stringIncludesInsensitive(response.detail, this.filterDescription))
        )
      }
    },
    hasRequests () {
      return this.approvalResponses.length > 0
    }
  },
  methods: {
    drilldownLink (investmentId) {
      return `drilldown?investment_id=${investmentId}&tab=approvals`
    },
    statusString (status) {
      return StringHelpers.koreanStatus(status)
    },
    responseUsers (response) {
      return response.approval_responses.map(resp => resp.user_name).join(',\n')
    }
  }
}
</script>
