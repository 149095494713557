<template>
  <div class='pb-8 px-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>나의 승인 내역</h1>
      </div>
    </portal>
    <approval-filters  />
    <h3 class='mt-12 text-xl uppercase'>
      승인 요청
      <span class='text-gray-500'>({{myApprovalRequestsCount}})</span>
    </h3>
    <my-approvals-my-requests class='mt-2' />

    <h3 class='mt-12 text-xl uppercase'>
      승인 필요
      <span class='text-gray-500'>({{myApprovalResponsesCount}})</span>
    </h3>
    <my-approvals-my-responses class='mt-2' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApprovalFilters         from '@/views/my_approvals/ApprovalFilters.vue'
import MyApprovalsMyRequests   from '@/views/my_approvals/MyApprovalsMyRequests.vue'
import MyApprovalsMyResponses  from '@/views/my_approvals/MyApprovalsMyResponses.vue'

export default {
  name: 'MyApprovals',
  components: {
    ApprovalFilters,
    MyApprovalsMyRequests,
    MyApprovalsMyResponses,
  },
  computed: {
    ...mapGetters('approvals', [
      'myApprovalRequestsCount',
      'myApprovalResponsesCount',
    ]),
  },
}
</script>
