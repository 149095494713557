<template>
  <div class='approval-filters flex flex-row justify-start items-center text-sm'>
    <el-date-picker
      style='width: 15rem;'
      v-model='dateRange'
      type='daterange'
      format='yyyy-MM-dd'
      value-format='yyyy-MM-dd'
      range-separator='~'
      start-placeholder='Start date'
      end-placeholder='End date'
      size='large'
      :picker-options='pickerOptions'>
    </el-date-picker>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs                    from 'dayjs'

export default {
  name: 'ApprovalFilters',
  data () {
    return {
      dateRange: [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      pickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last week',
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          },
        },
         {
          text: 'Last Moth',
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          },
        }]
      },
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.getApprovalRequests({start_date: this.startDate, end_date: this.endDate})
        this.getNeedMyApprovalResponse({start_date: this.startDate, end_date: this.endDate})
      },
      immediate: true,
    }
  },
  computed: {
    startDate () {
      return this.dateRange ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : null
    },
    endDate () {
      return this.dateRange ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
  },
  methods: {
    ...mapActions('approvals', [
      'getApprovalRequests',
      'getNeedMyApprovalResponse',
    ]),
  },
}
</script>
